export class Zusatzbezeichnungen {
  AdressenZusatzBezeichnung: string[];
  AnsprechAnrede: string[];
  AnsprechZusatzBezeichnung: string[];
  ObjektZusatzBezeichnung: string[];

  constructor(data: Zusatzbezeichnungen) {
    Object.assign(this, data);
  }
}
