export class AddListItem {
  name: string;
  imgpath: string;
  action: string;
  disabledByRight: boolean;
  disabledByLicence: boolean;
  show: boolean;

  constructor(
    name: string,
    imgpath: string,
    action: string,
    disabledByRight: boolean,
    disabledByLicence: boolean,
    show: boolean
  ) {
    this.name = name;
    this.imgpath = imgpath;
    this.action = action;
    this.disabledByRight = disabledByRight;
    this.disabledByLicence = disabledByLicence;
    this.show = show;
  }
}
