import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import * as uuid from 'uuid';

export class Mengeneinheit {
  Bez: string;
  Id: number;
  Uuid: string;

  constructor(objectData: Mengeneinheit) {
    GlobalHelper.assignIfPropertyExists(this, objectData);
  }

  createNew(Bez: string): void {
    this.Bez = Bez;
    this.Uuid = uuid.v4();
  }
}
