import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

type DialogType = 'confirm' | 'inform' | 'loading' | 'error';

export class DialogInformation {
  dialogType: DialogType;
  headline: string;
  message: string;
  confirmButtonText?: string;
  declineButtonText?: string;
  cancelOnClickOutside = false;
  htmlString?: string;

  constructor(
    dialogType: DialogType,
    headline: string,
    message: string,
    confirmButtonText: string = null,
    declineButtonText: string = null,
    cancelOnClickOutside = false,
    htmlString: string = null
  ) {
    if (dialogType === 'confirm' && GlobalHelper.isNullOrUndefinedOrEmptyString(confirmButtonText))
      throw new Error('Dialogfehler - ConfirmDialog verwendet ohne confirmMessage');
    this.dialogType = dialogType;
    this.headline = headline;
    this.message = message;
    this.confirmButtonText = confirmButtonText;
    this.declineButtonText = declineButtonText;
    this.cancelOnClickOutside = cancelOnClickOutside;
    this.htmlString = htmlString;
  }
}
