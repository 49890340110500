import { Right } from 'libs/shared/src/lib/entities';

export class Routeinformation {
  path: string;
  Recht: Right;
  constructor(path: string, Recht: Right) {
    this.path = path;
    this.Recht = Recht;
  }

  /**@description Guckt für die jeweilige Seite ob das Recht zum Erstellen besteht */
  hasCreateRightForSite(): boolean {
    const path = this.path;
    const Recht = this.Recht;
    const employeeRight = Recht.employeeRights;
    const adressRight = employeeRight.addressRights;
    const terminRight = employeeRight.appointmentRights;

    switch (path) {
      case '/adressen': {
        const createright = adressRight.editCustomers || adressRight.editFreeAddresses;
        return createright;
      }
      case '/Kalender': {
        const createright = terminRight.allowCreation;
        return createright;
      }
    }

    return true;
  }
}
